import React from 'react';

import { AccountHero, AccountLayout } from '../../components/account/common';
import { AccountRegisterForm } from '../../components/account/auth';

const AccountRegisterPage = (): JSX.Element => (
  <AccountLayout
    allowAnonymous
    hero={<AccountHero disableBackground title="Register" />}
  >
    <AccountRegisterForm />
  </AccountLayout>
);

export default AccountRegisterPage;
